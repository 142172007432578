import React from 'react';

export default function Hero() {
  return (
    <section>
      <div className='xcontainer'>
        <div className="hero h-96 md:h-[500px] rounded-none overflow-hidden" style={{ backgroundImage: `url("/bg.jpg")` }}>
          <div className="hero-overlay "></div>
          {/* // bg-opacity-60 bg-secondary */}
          <div className="hero-content text-center text-secondary-content">
            <div className="max-w-lg">
              <h1 className="mb-5 sm:mb-7 text-4xl sm:text-5xl font-bold">
                我们助力您的产品成功
              </h1>
              <p className="mb-5 sm:mb-7 sm:text-lg">
                我们为客户提供软件开发，服务器运维，企业系统维护，云服务维护等IT信息服务，解决企业技术问题，为客户的业务整合 AI 功能，让您的企业轻装上阵，如虎添翼
              </p>
              <a href="#about" className="btn btn-warning sm:btn-wide">立刻开始</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}