import React from 'react';

export default function Footer() {
  return (
    <footer id="about" className='bg-base-200 text-base-content '>
      <div className='container '>
        <div className="footer py-10 md:py-16 grid-cols-2 sm:grid-cols-3 lg:grid-cols-auto">
          <div>
            <span className="footer-title">服务</span>
            <a href="#about" className="link link-hover">软件开发</a>
            <a href="#about" className="link link-hover">服务器运维</a>
            <a href="#about" className="link link-hover">网站建设</a>
            <a href="#about" className="link link-hover">系统维护</a>
            <a href="//mdhub.gzfjsoft.com" className="link link-hover">MD Hub</a>
          </div>
          <div>
            <span className="footer-title">公司</span>
            <a href="#about" className="link link-hover">关于我们</a>
            <a href="#about" className="link link-hover">联系我们</a>
            <a href="#about" className="link link-hover"><i className='bi bi-phone  ' /> 15622302051</a>
            <a href="#about" className="link link-hover"><i className='bi bi-wechat  ' /> gzfjsoft</a>
            <a href="#about" className="link link-hover"><i className='bi bi-envelope ' /> support<i className='bi bi-at ' />gzfjsoft.com</a>
          </div>
          {/* <div>
          <span className="footer-title">Legal</span>
          <a href="#!" className="link link-hover">Terms of use</a>
          <a href="#!" className="link link-hover">Privacy policy</a>
          <a href="#!" className="link link-hover">Cookie policy</a>
          </div> */}
          <div className='w-full col-span-full md:col-auto'>
            <span className="footer-title">新闻</span>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">输入您的邮箱</span>
              </label>
              <div className="relative">
                <input type="text" placeholder="邮箱" className="input input-bordered w-full pr-16" />
                <button className="btn btn-primary absolute top-0 right-0 rounded-l-none">订阅</button>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col sm:flex-row items-center border-t border-base-300 py-4 gap-2'>
          <div className="flex-grow text-center sm:text-start">
            <p>©Copyright 2000 - 2024 广州丰捷软件技术有限公司</p>

          </div>
          <div className="grid grid-flow-col gap-4">
            <a target="blank" href="http://beian.miit.gov.cn/">粤ICP备15110461号</a>
            {/* <a className='link link-secondary' href='#!'><i className='bi bi-facebook text-xl'></i></a>
            <a className='link link-secondary' href='#!'><i className='bi bi-instagram text-xl'></i></a>
            <a className='link link-secondary' href='#!'><i className='bi bi-twitter text-xl'></i></a>
            <a className='link link-secondary' href='#!'><i className='bi bi-github text-xl'></i></a>
            <a className='link link-secondary' href='#!'><i className='bi bi-dribbble text-xl'></i></a> */}
          </div>
        </div>
      </div>
    </footer>
  )
}