import React from 'react';

export default function Services() {
  return (
    <section id="service" className='py-10 md:py-16  gradient-background' style={{ backgroundImage: `url("/wave.svg")`, backgroundRepeat: "no-repeat",backgroundPosition: "bottom" }}>
          
      <div className='container ' >
        <div className='text-center'>
          <h2 className='text-3xl sm:text-5xl font-bold mb-4'>我们的服务</h2>
          <p className='text-lg sm:text-2xl mb-6 md:mb-14'>
            协助您的企业更高效管理IT信息系统，让您专注于您的专业
          </p>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 lg:gap-8 xl:gap-10'>
          <div className="card bg-base-200 transform-gpu transition-all duration-300  opacity-80 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className='bi bi-search text-4xl'></i>
              <h2 className="card-title">SEO 服务</h2>
              <p>
                为你提供网页 SEO 服务<br className='hidden xl:inline' />让您的业务获取<br className='hidden xl:inline' />更多的用户
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 opacity-80 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className='bi bi-window-stack text-4xl'></i>
              <h2 className="card-title">软件开发</h2>
              <p>
                提高企业运作效率，信息化水平<br className='hidden xl:inline' />让数据指导决策
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 opacity-80 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className='bi bi-badge-ad text-4xl'></i>
              <h2 className="card-title">网站建设</h2>
              <p>
                提供高质量的现代网页 <br className='hidden xl:inline' />让你的企业更快<br className='hidden xl:inline' />更新企业信息产品信息
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 opacity-80 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className='bi bi-card-checklist text-4xl'></i>
              <h2 className="card-title">运维管理</h2>
              <p>
                帮助您的企业<br className='hidden xl:inline' />高效管理各种硬件网络服务
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 opacity-80 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className='bi bi-chat-left-dots text-4xl'></i>
              <h2 className="card-title">社区建设</h2>
              <p>
                提供客户管理工具<br className='hidden xl:inline' />让您的企业更好的触达用户
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 opacity-80 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className='bi bi-window-sidebar text-4xl'></i>
              <h2 className="card-title">企业系统维护</h2>
              <p>
                帮助您的企业评估选择 ERP CRM <br className='hidden xl:inline' />软件解决方案<br className='hidden xl:inline' />为您的企业出谋划策
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}