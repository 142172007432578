import React from "react";

export default function Testimonial() {
  return (
    <section id="client" className="py-10 md:py-16 bg-neutral">
      <div className="container">
        <div className="text-center text-white">
          <h2 className="text-3xl sm:text-5xl font-bold mb-4">为您的企业</h2>
          <p className="text-lg sm:text-2xl  md:mb-14">创造极致体验和无限可能</p>
          {/* mb-6 */}
        </div>

        {/* <div className="columns-1 sm:columns-2 lg:columns-3 gap-6 lg:gap-8">
          <div className="block mb-8 lg:mb-10">
            <div className="stack">
              <div className="card bg-secondary text-secondary-content">
                <div className="card-body gap-4">
                  <div className="flex items-center">
                    <div className="avatar flex-shrink-0">
                      <div className="w-16 rounded-full border-2 border-white">
                        <img alt="头像" alt="头像" src="https://placeimg.com/100/100/people" />
                      </div>
                    </div>

                    <div className="ml-3">
                      <p className="text-lg font-medium leading-tight">Eric Ampire</p>
                      <small>Mobile dev</small>
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum aliquid quo eum quae quos illo earum ipsa doloribus nostrum minus libero
                    aspernatur laborum cum, a suscipit, ratione ea totam ullam! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Architecto laboriosam
                    deleniti aperiam ab veniam sint non cumque quis tempore cupiditate. Sint libero voluptas veniam at reprehenderit, veritatis harum et rerum.
                  </p>
                </div>
              </div>
              <div className="card bg-primary h-10"></div>
              <div className="card bg-warning h-10"></div>
            </div>
          </div>

          <div className="block mb-8 lg:mb-10">
            <div className="stack">
              <div className="card bg-secondary text-secondary-content">
                <div className="card-body gap-4">
                  <div className="flex items-center">
                    <div className="avatar flex-shrink-0">
                      <div className="w-16 rounded-full border-2 border-white">
                        <img alt="头像" alt="头像" src="https://placeimg.com/99/99/people" />
                      </div>
                    </div>

                    <div className="ml-3">
                      <p className="text-lg font-medium leading-tight">Adam Wathan</p>
                      <small>Creator of Tailwindcss</small>
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Architecto laboriosam deleniti aperiam ab veniam sint non cumque quis tempore
                    cupiditate. Sint libero voluptas veniam at reprehenderit, veritatis harum et rerum.
                  </p>
                </div>
              </div>
              <div className="card bg-primary h-10"></div>
              <div className="card bg-warning h-10"></div>
            </div>
          </div>

          <div className="block mb-8 lg:mb-10">
            <div className="stack">
              <div className="card bg-secondary text-secondary-content">
                <div className="card-body gap-4">
                  <div className="flex items-center">
                    <div className="avatar flex-shrink-0">
                      <div className="w-16 rounded-full border-2 border-white">
                        <img alt="头像" alt="头像" src="https://placeimg.com/98/98/people" />
                      </div>
                    </div>

                    <div className="ml-3">
                      <p className="text-lg font-medium leading-tight">Ben Sulumu</p>
                      <small>Manager</small>
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Architecto laboriosam deleniti aperiam ab veniam sint non cumque quis tempore
                    cupiditate. Sint libero voluptas veniam at reprehenderit, veritatis harum et rerum.
                  </p>
                </div>
              </div>
              <div className="card bg-primary h-10"></div>
              <div className="card bg-warning h-10"></div>
            </div>
          </div>

          <div className="block mb-8 lg:mb-10">
            <div className="stack">
              <div className="card bg-secondary text-secondary-content">
                <div className="card-body gap-4">
                  <div className="flex items-center">
                    <div className="avatar flex-shrink-0">
                      <div className="w-16 rounded-full border-2 border-white">
                        <img alt="头像" alt="头像" src="https://placeimg.com/97/97/people" />
                      </div>
                    </div>

                    <div className="ml-3">
                      <p className="text-lg font-medium leading-tight">Rodrigo Aguilar</p>
                      <small>Creator of Tailwind Awesome</small>
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Architecto laboriosam deleniti aperiam ab veniam sint non cumque quis tempore
                    cupiditate. Sint libero voluptas veniam at reprehenderit, veritatis harum et rerum.
                  </p>
                </div>
              </div>
              <div className="card bg-primary h-10"></div>
              <div className="card bg-warning h-10"></div>
            </div>
          </div>

          <div className="block mb-8 lg:mb-10">
            <div className="stack">
              <div className="card bg-secondary text-secondary-content">
                <div className="card-body gap-4">
                  <div className="flex items-center">
                    <div className="avatar flex-shrink-0">
                      <div className="w-16 rounded-full border-2 border-white">
                        <img alt="头像" src="https://placeimg.com/96/96/people" />
                      </div>
                    </div>

                    <div className="ml-3">
                      <p className="text-lg font-medium leading-tight">Dan Kyungu</p>
                      <small>Mobile dev</small>
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Architecto laboriosam deleniti aperiam ab veniam sint non cumque quis tempore
                    cupiditate. Sint libero voluptas veniam at reprehenderit, veritatis harum et rerum.
                  </p>
                </div>
              </div>
              <div className="card bg-primary h-10"></div>
              <div className="card bg-warning h-10"></div>
            </div>
          </div>

          <div className="block mb-8 lg:mb-10">
            <div className="stack">
              <div className="card bg-secondary text-secondary-content">
                <div className="card-body gap-4">
                  <div className="flex items-center">
                    <div className="avatar flex-shrink-0">
                      <div className="w-16 rounded-full border-2 border-white">
                        <img alt="头像" src="https://placeimg.com/95/95/people" />
                      </div>
                    </div>

                    <div className="ml-3">
                      <p className="text-lg font-medium leading-tight">Randy Doe</p>
                      <small>Mobile dev</small>
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Architecto laboriosam deleniti aperiam ab veniam sint non cumque quis tempore
                    cupiditate. Sint libero voluptas veniam at reprehenderit, veritatis harum et rerum.
                  </p>
                </div>
              </div>
              <div className="card bg-primary h-10"></div>
              <div className="card bg-warning h-10"></div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}
